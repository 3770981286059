import React from 'react';
import cls from 'classnames';

import { UISref, useRouter } from '@wix/tpa-router/react';
import { useEnvironment, useTranslation, useBi } from '@wix/yoshi-flow-editor';

import { TextButton } from 'wui/TextButton';
import { IconButton } from 'wui/IconButton';
import { ChevronRight as ChevronRightIcon } from '@wix/wix-ui-icons-common/on-stage';

import { groupsSearchSearchResultClickSrc111Evid525 } from '@wix/bi-logger-groups-data/v2';

import type { IGroup } from 'store/groups';

import { Box } from 'wui/Box';
import { ListItem } from 'wui/ListItem';
import { ListItemButton } from 'wui/ListItemButton';
import { Button } from 'wui/Button';
import { ListItemText } from 'wui/ListItemText';
import { ListItemAction } from 'wui/ListItemAction';
import { Typography } from 'wui/Typography';
import type { TPAComponentProps } from 'wui/types';

import { GroupInfo } from 'common/components/GroupInfo';

import { GroupListItemImage } from './GroupListItemImage';

import classes from './GroupListItem.scss';

interface IGroupListItemProps extends TPAComponentProps {
  group: IGroup;
  origin: string;
  query: string;
}

export function JoinedGroupListItem(props: IGroupListItemProps) {
  const { group, origin, query, ...rest } = props;

  const { t } = useTranslation();
  const bi = useBi();
  const { isMobile } = useEnvironment();

  const router = useRouter();

  if (isMobile) {
    return (
      <ListItem disablePadding {...rest}>
        <ListItemButton
          onClick={() => handleGroupClick()}
          className={cls(classes.root, classes.mobile)}
        >
          <GroupListItemImage group={group} />

          <ListItemText
            title={
              <Box verticalAlign="middle">
                <Typography noWrap variant="p2-14" data-hook="group-name">
                  {group.name}
                </Typography>
              </Box>
            }
            subtitleProps={{ noWrap: true }}
            subtitle={
              <GroupInfo
                wired
                misc
                data-hook="group-info"
                groupId={group.id as string}
              />
            }
          />
          <ListItemAction>
            <IconButton tabIndex={-1} icon={<ChevronRightIcon />} />
          </ListItemAction>
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <ListItem {...rest} className={cls(classes.root, props.className)}>
      <UISref
        state="group"
        params={{ slug: group.slug }}
        bi={groupsSearchSearchResultClickSrc111Evid525({
          origin,
          groupId: group.id as string,
          tabName: 'groups',
          inputText: query,
        })}
      >
        <a tabIndex={-1} className={classes.imageLink}>
          <GroupListItemImage group={group} />
        </a>
      </UISref>

      <ListItemText
        title={
          <Box verticalAlign="middle">
            <Typography variant="p2-16" noWrap>
              <UISref
                state="group"
                params={{ slug: group.slug }}
                bi={groupsSearchSearchResultClickSrc111Evid525({
                  origin,
                  groupId: group.id as string,
                  tabName: 'groups',
                  inputText: query,
                })}
              >
                <TextButton as="a" variant="secondary" data-hook="group-name">
                  {group.name}
                </TextButton>
              </UISref>
            </Typography>
          </Box>
        }
        subtitle={
          <GroupInfo
            data-hook="group-info"
            groupId={group.id as string}
            variant="p2-14"
            noPendingMembers
          />
        }
      />

      <ListItemAction>
        <Button
          size="tiny"
          outlined
          onClick={() => handleGroupClick()}
          className={classes.tinyButton}
        >
          {t('groups-web.btn.view-group')}
        </Button>
      </ListItemAction>
    </ListItem>
  );

  function handleGroupClick() {
    bi.report(
      groupsSearchSearchResultClickSrc111Evid525({
        origin,
        groupId: group.id as string,
        tabName: 'groups',
        inputText: query,
      }),
    );
    return router.go('group', { slug: group.slug });
  }
}

JoinedGroupListItem.displayName = 'JoinedGroupListItem';
