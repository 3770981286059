import React, { useCallback, useState } from 'react';
import cls from 'classnames';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import {
  groupsCreateGroupClick,
  groupsLivesiteClick,
  groupsSearchForGroup,
} from '@wix/bi-logger-groups/v2';

import { useStyles } from '@wix/tpa-settings/react';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';

import styleParams from 'Groups/stylesParams';

import {
  selectCanCreateGroup,
  selectCanCreateGroupRequests,
  selectGroups,
  selectIsLoggedIn,
} from 'store/selectors';
import { EGroupsNamespace } from 'store/groups/constants';

import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';
import { useBiParams } from 'common/hooks/useBiParams';

import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import { Stack } from 'wui/Stack';
import { TextField } from 'wui/TextField';
import { Typography } from 'wui/Typography';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Divider } from 'wui/Divider';

import { EmptyGroupList } from '../../../EmptyGroupList';
import { Search } from '../../../Search';
import { GroupList, GroupListSkeleton } from '../GroupList';

import classes from './Groups.scss';

interface IProps extends React.ComponentProps<typeof Box> {
  wired?: boolean;
}

export function Groups(props: IProps) {
  const { wired, className, ...rest } = props;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const bi = useBi();
  const biParams = useBiParams();
  const { params } = useCurrentStateAndParams();

  const styles = useStyles();

  const { groups$, application$ } = useController();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const joined = useSelector(selectGroups(EGroupsNamespace.JOINED));
  const suggested = useSelector(selectGroups(EGroupsNamespace.SUGGESTED));
  const canCreateGroup = useSelector(selectCanCreateGroup);
  const canCreateGroupRequests = useSelector(selectCanCreateGroupRequests);

  const loading =
    joined.status.fetch?.loading || suggested.status.fetch?.loading;

  const title = styles.get(styleParams.sidebarTitleFont);

  const [query, setQuery] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const queryGroups = useCallback(
    debounce((title: string) => {
      bi.report(
        groupsSearchForGroup({
          origin: 'sidebar_livesite',
        }),
      );

      groups$.query({
        ...params,
        title,
        namespace: EGroupsNamespace.SUGGESTED,
        partition: EGroupsNamespace.SUGGESTED,
      });

      groups$.query({
        ...params,
        title,
        namespace: EGroupsNamespace.JOINED,
        partition: EGroupsNamespace.JOINED,
      });
    }, 500),
    [],
  );

  useDidUpdate(() => {
    queryGroups(query);
  }, [query]);

  return (
    <Box
      gap="SP6"
      height="100%"
      direction="vertical"
      paddingTop={isMobile ? 'SP0' : 'SP5'}
      paddingBottom={isMobile ? 'SP0' : 'SP5'}
      className={cls(classes.root, className, { [classes.wired]: props.wired })}
      {...rest}
    >
      <Box
        direction="vertical"
        className={classes.search}
        paddingLeft={isMobile ? 'SP0' : 'SP5'}
        paddingRight={isMobile ? 'SP0' : 'SP5'}
      >
        <TextField
          theme="box"
          wired={wired}
          value={query}
          withClearButton
          onClear={handleReset}
          prefix={<SearchIcon />}
          placeholder={t('groups-web.search.placeholder')}
          onChange={handleQueryChange}
          onClick={handleSearchClick}
        />
      </Box>

      <nav
        className={classes.content}
        aria-label={t('groups-web.group-list.aria-label')}
      >
        <Show if={loading}>
          <GroupListSkeleton wired={wired} />
        </Show>

        <Hide if={loading}>
          <Stack
            direction="vertical"
            gap={isMobile ? 'SP4' : 'SP6'}
            separator={
              <Divider
                bw={isMobile}
                inset={!isMobile}
                className={classes.sectionDivider}
              />
            }
          >
            <Show if={isLoggedIn}>
              <Box
                as="section"
                direction="vertical"
                gap={isMobile ? 'SP4' : 'SP6'}
                paddingLeft={isMobile ? 'SP0' : 'SP5'}
                paddingRight={isMobile ? 'SP0' : 'SP5'}
              >
                <Typography
                  variant="p2-16"
                  as={title.htmlTag as React.ElementType}
                  className={cls(classes.textAlign, {
                    [classes.title]: !isMobile,
                  })}
                >
                  {t('groups-web.tabs.my-groups')}
                </Typography>
                <GroupList
                  wired={wired}
                  groups={joined.groups}
                  meta={joined.meta}
                  data-hook="my-groups"
                  status={joined.status}
                  onRetry={handleJoinedRetry}
                  onLoadMore={handleJoinedLoadMore}
                  emptyState={
                    <EmptyGroupList
                      bw={isMobile}
                      variant="section"
                      title={undefined}
                      filters={{ title: query }}
                      data-type="my-groups-empty"
                      className={classes.emptyState}
                      partition={EGroupsNamespace.JOINED}
                    />
                  }
                />
              </Box>
            </Show>
            <Show if={!isLoggedIn || Boolean(suggested.meta.total)}>
              <Box
                as="section"
                direction="vertical"
                className={classes.suggestedGroups}
                gap={isMobile ? 'SP4' : 'SP6'}
                paddingLeft={isMobile ? 'SP0' : 'SP5'}
                paddingRight={isMobile ? 'SP0' : 'SP5'}
              >
                <Typography
                  variant="p2-16"
                  as={title.htmlTag as React.ElementType}
                  className={cls(classes.textAlign, {
                    [classes.title]: !isMobile,
                  })}
                >
                  {t('groups-web.tabs.suggested-groups')}
                </Typography>

                <GroupList
                  wired={wired}
                  groups={suggested.groups}
                  meta={suggested.meta}
                  status={suggested.status}
                  onRetry={handleSuggestedRetry}
                  onLoadMore={handleSuggestedLoadMore}
                  emptyState={
                    <EmptyGroupList
                      bw={isMobile}
                      title={undefined}
                      variant="section"
                      data-type="suggested-groups-empty"
                      filters={{ title: query }}
                      className={classes.emptyState}
                      partition={EGroupsNamespace.SUGGESTED}
                    />
                  }
                />
              </Box>
            </Show>
          </Stack>
        </Hide>
      </nav>
      <Show if={canCreateGroup || canCreateGroupRequests}>
        <Box
          className={classes.actions}
          paddingLeft={isMobile ? 'SP0' : 'SP5'}
          paddingRight={isMobile ? 'SP0' : 'SP5'}
        >
          <Button outlined fullWidth onClick={openCreateGroupDialog}>
            {t('groups-web.btn.create-new')}
          </Button>
        </Box>
      </Show>

      <Search
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
        origin="central_feed"
      />
    </Box>
  );

  function handleSearchClick() {
    if (!isMobile) {
      setIsSearchOpen(true);
    }
  }

  function openCreateGroupDialog() {
    bi.report(
      groupsCreateGroupClick({
        origin: 'new_layout_sidebar',
      }),
    );
    application$.showDialog({ dialog: 'createGroup' });
  }

  function handleQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
  }

  function handleReset() {
    setQuery('');
  }

  function handleJoinedLoadMore() {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreenWithTab(EGroupsNamespace.JOINED),
        button_name: 'show_more_groups',
      }),
    );
    groups$.query({
      ...params,
      title: query,
      offset: joined.groups.length,
      namespace: EGroupsNamespace.JOINED,
      partition: EGroupsNamespace.JOINED,
    });
  }

  function handleSuggestedLoadMore() {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreenWithTab(EGroupsNamespace.SUGGESTED),
        button_name: 'show_more_groups',
      }),
    );
    groups$.query({
      ...params,
      title: query,
      offset: suggested.groups.length,
      namespace: EGroupsNamespace.SUGGESTED,
      partition: EGroupsNamespace.SUGGESTED,
    });
  }

  function handleJoinedRetry() {
    groups$.query({
      ...params,
      title: query,
      namespace: EGroupsNamespace.JOINED,
      partition: EGroupsNamespace.JOINED,
    });
  }

  function handleSuggestedRetry() {
    groups$.query({
      ...params,
      title: query,
      namespace: EGroupsNamespace.SUGGESTED,
      partition: EGroupsNamespace.SUGGESTED,
    });
  }
}

Groups.displayName = 'Groups';
